<template>
  <div>
    <b-button-group class="mb-1" v-if="type != 3">
      <b-button
        :variant="!+lang ? 'primary' : 'outline-primary'"
        :to="'/admin/main/' + this.$route.name + '/0'"
        >عربي</b-button
      >
      <b-button
        :to="'/admin/main/' + this.$route.name + '/1'"
        :variant="!+lang ? 'outline-primary' : 'primary'"
        >English</b-button
      >
    </b-button-group>
    <div v-else class="d-flex align-items-center mb-1">
      <small>إخفاء</small>
      <b-form-checkbox
        switch
        class="mx-50"
        v-model="settings.showCustomers"
      ></b-form-checkbox>
      <small>إظهار</small>
    </div>
    <b-row>
      <b-col cols="12" md="6" lg="3" v-for="(item, i) in ActiveDataList" :key="i">
        <component :is="$route.name + '-card'" :item="item"></component>
      </b-col>
      <b-col cols="12" class="d-flex justify-content-center">
        <b-pagination
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          :value="DataFirstPage"
          :total-rows="filterdDataList.length"
          :per-page="DataPageLength"
          @input="(value) => updateDataPagination(value)"
        >
          <template #prev-text>
            <unicon width="20" name="angle-right" fill="#A41F02" />
          </template>
          <template #next-text>
            <unicon width="20" name="angle-left" fill="#A41F02" />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
const servicesCard = () => import("./services/service-card");
const productsCard = () => import("./products/product-card");
const activitesCard = () => import("./activites/activite-card");
const customersCard = () => import("./customers/customer-card");
export default {
  props: {
    lang: String,
  },
  components: {
    servicesCard,
    productsCard,
    activitesCard,
    customersCard,
  },

  computed: {
    ...mapGetters([
      "filterdDataList",
      "ActiveDataList",
      "DataFirstPage",
      "DataPageLength",
      "settings",
    ]),
    type() {
      switch (this.$route.name) {
        case "services":
          return 0;
        case "products":
          return 1;
        case "activites":
          return 2;
        case "customers":
          return 3;
      }
      return false;
    },
  },
  created() {
    this.getData({ type: this.type, lang: !+this.lang ? 1 : 2 });
    this.$store.dispatch("app/setLang", !+this.lang ? 1 : 2);
  },
  methods: {
    ...mapActions(["getData", "updateDataPagination", "updateShowCustomers"]),
  },
  watch: {
    type(type) {
      this.getData({ type, lang: !+this.lang ? 1 : 2 });
    },
    lang(lang) {
      this.getData({ type: this.type, lang: !+lang ? 1 : 2 });
      this.$store.dispatch("app/setLang", !+lang ? 1 : 2);
    },
    "settings.showCustomers"() {
      this.updateShowCustomers({
        id: this.settings.id,
        showCustomers: this.settings.showCustomers,
      });
    },
  },
};
</script>
